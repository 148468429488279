import React, {Component} from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { api_url, api_token, DNCContext } from '../../context';
import { Button, Container } from 'react-bootstrap';
import PageHeader from '../molecules/PageHeader';
import PhoneInput from 'react-phone-number-input/input';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const validateEmail = (str) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(str);
}

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
// NEED TO CREATE VARIABLES FOR JSON BODY
class PostRequestSetHeaders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: null,
            last_name: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zip: null,
            home_phone: null,
            work_phone: null,
            mobile_phone: null,
            primary_email: null,
            secondary_email: null,
            organization: null,
            dnc_request_date: new Date(),
            edit: props.edit || false,
            submitting: false,
            error: false
        };
    }

    static contextType = DNCContext

    componentDidMount() {
        if(this.props.edit) {
            const { contact } = this.context
            if(contact) {
                this.setState({
                ...contact,
                dnc_request_date: contact.dnc_request_date && isValidDate(contact.dnc_request_date) ? new Date(contact.dnc_request_date) : new Date()
                })
            }
        }
    }

    submitHandler = (e) => {
        let successUrl = 'https://do-not-contact.netlify.app/success';
        e.preventDefault();
        const headers = {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+api_token,
            // live: O56Xj64U3vFPHk8p00tZFWhyZOXVdbSEc4HqvkIat1DHqR7QjtFMKd3zNoh0P5G9
            // staging: Tb4kKydzqvGd1qdzIOvUtsIBg2l2mq2YTsPyFplSGqe9GgNehZe6SnxjGxSxCRp0
          }
      
        const copyState = {...this.state}
        if(copyState.primary_email == null && copyState.mobile_phone == null ) {
            alert('Please provide at least a mobile phone number or an email.');
        } 
        else if(copyState.primary_email && !validateEmail(copyState.primary_email.trim())) {
            alert('Please provide a valid email.');
        }
        else {
            delete copyState.edit
            delete copyState.id
            delete copyState.submitting
            delete copyState.error
            
            Object.keys(copyState).forEach(key => {
                if(copyState[key] === null) {
                    copyState[key] = 'NULL';
                }
            })

            this.setState({
                submitting: true,
                error: false
            })
            if(this.props.edit) {
                copyState.dnc_data_id = copyState.record_id
                delete copyState.record_id
                axios.put(
                    api_url+'/edit_dnc',
                    {
                        ...copyState,
                        dnc_request_date: moment(copyState.dnc_request_date).format('YYYY-MM-DD')
                    },
                    {
                        headers: headers
                    }
                ).then(() => {
                    window.location.href = "/success?action=updated";
                })
                .catch((error) => {
                    this.setState({
                        submitting: false,
                        error: error.response.data.insert_result
                    })
                })
            } else {
                axios.post(
                    api_url+'/add_dnc',
                    {
                        ...copyState,
                        dnc_request_date: moment(copyState.dnc_request_date).format('YYYY-MM-DD')
                    },
                    {
                        headers: headers
                    }
                ).then(() => {
                    window.location.href = "/success";
                })
                .catch((error) => {
                    this.setState({
                        submitting: false,
                        error: error.response.data.insert_result
                    })
                })
            }
        }

    }

    handleChangePhone = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value.replace(/^(0|1)/, "")
        })
    }
    render() {
        const {
            edit,
            first_name,
            last_name,
            address1,
            address2,
            city,
            state,
            zip,
            home_phone,
            mobile_phone,
            work_phone,
            primary_email,
            secondary_email,
            organization,
            dnc_request_date,
            error
        } = this.state;

        return (
        <Container>
            <PageHeader title={edit ? 'Edit Contact' : 'Add New Contact'} />
            <form action="" id="addNewContact" className="add-new-contact p-5" onSubmit={this.submitHandler}>
                <div className="form-sub-section">
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">First Name</label>
                        <input type="text" id="first_name"  maxLength={50} pattern='[a-zA-Z0-9\s]+' value={first_name} className="form-control first-name" onChange={e => this.setState({first_name: e.target.value})} defaultValue={first_name} />
                    </fieldset>
                    </div>
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">Last Name</label>
                        <input type="text" id="last_name"  maxLength={50} pattern='[a-zA-Z0-9\s]+' value={last_name} className="form-control last-name" onChange={e => this.setState({last_name: e.target.value})} defaultValue={last_name} />
                    </fieldset>
                    </div>
                </div>

                <div className="form-sub-section">
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">Address</label>
                        <input type="text" id="address1"   value={address1} className="form-control address" onChange={e => this.setState({address1: e.target.value})} defaultValue={address1} />
                    </fieldset>
                    </div>
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">Address 2</label>
                        <input type="text" id="address2" value={address2} className="form-control address-2" onChange={e => this.setState({address2: e.target.value})} defaultValue={address2} />
                    </fieldset>
                    </div>
                </div>

                <div className="form-sub-section">
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">City</label>
                        <input type="text" id="city" value={city}  className="form-control city" onChange={e => this.setState({city: e.target.value})} defaultValue={city} />
                    </fieldset>
                    </div>
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">State</label>
                        <input type="text" id="state" value={state}  className="form-control state" onChange={e => this.setState({state: e.target.value})} defaultValue={state} />
                    </fieldset>
                    </div>
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">Zip</label>
                        <input type="text" id="zip"  value={zip}   pattern="[0-9]{5}" className="form-control zip" onChange={e => this.setState({zip: e.target.value})} defaultValue={zip} />
                    </fieldset>
                    </div>
                </div>

                <div className="form-sub-section">
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">Mobile Phone</label>
                        <input 
                            type="text"
                            value={mobile_phone}
                            pattern='[0-9]{10}'
                            maxLength={10}
                            name="mobile_phone"
                            className='form-control'
                            onChange={this.handleChangePhone } />
                    </fieldset>
                    </div>
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">Home Phone</label>
                        <input 
                            type="text"
                            value={home_phone}
                            pattern='[0-9]{10}'
                            maxLength={10}
                            name="home_phone"
                            className='form-control'
                            onChange={this.handleChangePhone } />
                    </fieldset>
                    </div>
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">Work Phone</label>
                        <input 
                            type="text"
                            value={work_phone}
                            pattern='[0-9]{10}'
                            maxLength={10}
                            name="work_phone"
                            className='form-control'
                            onChange={this.handleChangePhone } />
                    </fieldset>
                    </div>
                </div>

                <div className="form-sub-section">
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">Email Address</label>
                        <input type="email"   id="primary_email" className="form-control email-address" onChange={e => this.setState({primary_email: e.target.value})} defaultValue={primary_email} />
                    </fieldset>
                    </div>
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">Additional Email Address</label>
                        <input type="email" id="secondary_email" className="form-control additional-email-address" onChange={e => this.setState({secondary_email: e.target.value})} defaultValue={secondary_email} />
                    </fieldset>
                    </div>
                </div>

                <div className="form-sub-section">
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">Business Unit</label>
                        <input type="text" id="organization" className="form-control business-unit" onChange={e => this.setState({organization: e.target.value})} defaultValue={organization}/>
                    </fieldset>
                    </div>
                    <div className="form-input-wrapper mr-3">
                    <fieldset className="form-fieldset">
                        <label className="field-label">Date Requested</label>
                        <DatePicker
                            selected={dnc_request_date}
                            onChange={(value) => {
                                this.setState({
                                    dnc_request_date: value
                                })
                            }}
                            dateFormat={"yyyy-MM-dd"}
                        />
                    </fieldset>
                    </div>
                </div>

                {error && <p style={{color: 'red'}}>
                    { error }
                </p>}

                <Button disabled={this.state.submitting} type='submit' className='ig-btn-primary px-5'>
                    SUBMIT
                </Button>
                
            </form>
        </Container>            
        );
    }
}

export default PostRequestSetHeaders; 
