import React from "react";
import logo from '../../images/Integrity_H_W.svg';

function PrimaryHeader() {
    return(
        <>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
        </>
    );
}

export default PrimaryHeader;