import { useContext } from 'react';
import { CSVLink } from 'react-csv';
import { DNCContext } from '../../context';

const CSV_HEADERS = [
  {label: 'First Name', key: 'first_name'},
  {label: 'Last Name', key: 'last_name'},
  {label: 'Address1', key: 'address1'},
  {label: 'Address2', key: 'address2'},
  {label: 'City', key: 'city'},
  {label: 'State', key: 'state'},
  {label: 'Zip', key: 'zip'},
  {label: 'Home Phone', key: 'home_phone'},
  {label: 'Mobile Phone', key: 'mobile_phone'},
  {label: 'Work Phone', key: 'work_phone'},
  {label: 'Email Address', key: 'primary_email'},
  {label: 'Secondary Email Address', key: 'secondary_email'},
  {label: 'Organization Name', key: 'organization_name'},
  {label: 'Request Date', key: 'dnc_request_date'}
]

export default function() {
    const { filteredData } = useContext(DNCContext);
    return (
        <CSVLink
            data={filteredData}
            headers={CSV_HEADERS}
            className="btn btn-light custom-btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                  </svg> <span style={{marginLeft: 5}}>Export</span>
        </CSVLink>
    )
}