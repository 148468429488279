import React from "react";
import { useLocation } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  
function Success() {
    let query = useQuery();
    return(
        <>
            <section className="add-new-contact-success">
                <div className="add-new-contact-success-container">
                    <h2>Add New Contact</h2>
                    <br/>
                    <p className="blue-text bold">You have successfully {query.get('action') || 'added'} this contact to the list.</p>
                    <div className="success-btn-container">
                        <a className="button" href="/add-contact">Add Another</a>
                        <a className="button" href="/">Return To List</a>
                    </div>
                </div>
            </section> 
        </>
    );
}

export default Success;