import React from 'react';

const DncTable = ({item, key, onClick}) => (
    <tr key={key} style={{cursor: 'pointer'}} onClick={onClick}>
        <td><span id="firstName" className="first-name">{item.first_name}</span></td>
        <td><span id="lastName" className="last-name">{item.last_name}</span></td>
        <td><span id="addressWrapper" className="full-address"><span id="address1">{item.address1}</span> <span id="address2">{item.address2}</span> <span id="city">{item.city}</span>, <span id="state">{item.state}</span> <span id="zip">{item.zip}</span></span></td>
        <td>
        <p className="mobile-phone-wrapper">M: <span id="mobilePhone">{item.mobile_phone}</span></p>
        <p className="mobile-phone-wrapper">H: <span id="homePhone">{item.home_phone}</span></p>
        <p className="mobile-phone-wrapper">W: <span id="workPhone">{item.work_phone}</span></p>
        </td>
        <td>
        <p className="email-address-wrapper"><span id="primary_email">{item.primary_email}</span></p>
        <p className="email-address-wrapper"><span id="secondary_email">{item.secondary_email}</span></p>
        </td>
        <td>
        <p className="business-info-wrapper">Business Unit: <span id="businessUnit">{item.organization}</span></p>
        <p className="business-info-wrapper">Created: <span id="created">{item.dnc_request_date}</span></p>
        </td>
    </tr>
);

export default DncTable;
