import { React, useContext, useEffect } from 'react';
import FetchDnc from './FetchDnc';
import Paginate from './Paginate';
import { DNCContext, api_token, api_url } from '../../context';
import _ from 'lodash';
import { useState } from 'react';
import { Container, Table, Spinner } from 'react-bootstrap';
import SortIcon from '../molecules/SortIcon';

function DncTableContainer()  {
    const {filteredData, setFilteredData, dncData, setDncData, loading, setLoading } = useContext(DNCContext);
    const [order, setOrder] = useState('asc');

    const sortData = (key) => {
        setFilteredData(_.orderBy(filteredData, key, order))
        setOrder(order == 'asc' ? 'desc' : 'asc')
    }

    useEffect(() => {
        setLoading(true)
        fetch(api_url+'/get_dnc', {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${api_token}`,
          }
          })
          .then(response => response.json())
          .then(data => {
            setDncData(data)
            setFilteredData(data)
            setLoading(false)
          })
          .catch(e => {
            setLoading(false)
            console.error(e.message)
          }) 
    }, [])
    return(
        <>
        {!loading && filteredData.length > 0 &&
            <>
           
            <section className="do-not-contact-table">
                <div className="do-not-contact-container">
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th width={"12.5%"}>
                                <SortIcon title={"First Name"}  order={order} onClick={() => sortData('first_name')} />
                            </th>
                            <th width={"12.5%"}>
                                <SortIcon title={"Last Name"}  order={order} onClick={() => sortData('last_name')} />    
                            </th>
                            <th width={'25%'}>
                                <SortIcon title={"Address"}  order={order} onClick={() => sortData('address1')} />
                            </th>
                            <th width={"20%"}>
                                <SortIcon title={"Phone Number(s)"}  order={order} onClick={() => sortData('home_phone')} />
                               
                            </th>
                            <th width={"15%"}> 
                                <SortIcon title={"Email Address(es)"}  order={order} onClick={() => sortData('primary_email')} />
                               
                            </th>
                            <th width={"15%"}>
                                <SortIcon title={"Created"}  order={order} onClick={() => sortData('dnc_request_date')} />
                         
                            </th>
                        </tr>
                        </thead>
                        <FetchDnc />
                    </Table>
                    <Paginate/>
                </div>
            </section>
            </>
        } 
        {!loading && filteredData.length == 0 && <p style={{margin: `2rem`}}>No results found.</p>}
        {loading && <Container style={{marginTop: '10%'}}>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </Container>}
        </>
    );
}

export default DncTableContainer;