import { createContext } from 'react';
export const isProduction = process.env.NODE_ENV === 'production';
export const api_url = isProduction ? 'https://int-dnc-api.tawebhost.com/api/v1.0' : 
'https://int-dnc-api.staging.tawebhost.com/api/v1.0';
export const api_token = isProduction ? 'O56Xj64U3vFPHk8p00tZFWhyZOXVdbSEc4HqvkIat1DHqR7QjtFMKd3zNoh0P5G9': 'Tb4kKydzqvGd1qdzIOvUtsIBg2l2mq2YTsPyFplSGqe9GgNehZe6SnxjGxSxCRp0';
export const DNCContext = createContext({
    dncData: [],
    setDncData: () => {},
    filteredData: [],
    setFilteredData: () => {},
    offset: 0,
    setOffset: () => {},
    limit: 10,
    setLimit: () => {},
    pageSize: 10,
    setPageSize: () => {},
    activeIndex: 0,
    setActiveIndex: () => {},
    api_url,
    api_token,
    loading: false,
    setLoading: () => boolean,
    contact: null,
    setContact: () => {}
});
