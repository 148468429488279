// the sub header will take in molecules/search.js and atoms/button.js
import React from 'react';
import { useContext } from 'react';
import { DNCContext } from '../../context';
import { useState } from 'react';
import { Button, Container, FormControl } from 'react-bootstrap';
import { useEffect } from 'react';
import ExportCSV from './ExportCSV';

function SubHeader() {
    const { dncData, setFilteredData, setActiveIndex, setOffset } = useContext(DNCContext);
    const [keyword, setKeyword] = useState('');

    const handleSearchKeyword = () => {
      if(keyword) {
        setFilteredData(dncData.filter(d => 
          d.last_name && d.last_name.toLowerCase().includes(keyword) ||
          d.first_name && d.first_name.toLowerCase().includes(keyword) ||
          d.address1 && d.address1.toLowerCase().includes(keyword) ||
          d.primary_email && d.primary_email.toLowerCase().includes(keyword) ||
          d.organization && d.organization.toLowerCase().includes(keyword) ||
          d.city && d.city.toLowerCase().includes(keyword) ||
          d.state && d.state.toLowerCase().includes(keyword) ||
          d.zip && d.zip.toLowerCase().includes(keyword) ||
          d.home_phone && d.home_phone.toLowerCase().includes(keyword) 
          
        ));
      } else {
        setFilteredData(dncData);
      }
      //reset pagination activeIndex
      setActiveIndex(0);
      setOffset(0);
    }

    useEffect(() => {
      handleSearchKeyword()
    }, [keyword])
    return(
        <div className="search-header">
          <Container style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>
                <Button variant="light" className="mr-4 custom-btn" href="/import-csv">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
            </svg>  <span style={{marginLeft: 5  }}>Import</span>
                </Button>
                <ExportCSV />
              </div>
              <div style={{display: 'flex'}}>
            
                <FormControl 
                  onChange={(e) => setKeyword(e.target.value.toLowerCase())}
                  type="text"
                  id="searchInput"
                  className="mr-4 custom-input"
                  placeholder="Search name, email etc..."
                  />
                <a className='button' href='/add-contact'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                </svg> Add new
                </a>   
              </div>
          </Container>
        </div>
    );
}

export default SubHeader;