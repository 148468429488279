import { useContext } from "react";
import {Pagination, Form} from "react-bootstrap";
import { DNCContext } from "../../context";
function Paginate() {
    const { 
        filteredData,
        setLimit,
        setOffset,
        offset,
        pageSize,
        setPageSize,
        activeIndex,
        setActiveIndex 
    } = useContext(DNCContext);
    const pages = Math.ceil(filteredData.length / pageSize);
    const rowsPerPage = [10,25,50,100];
    const pageNumbers = [...Array(pages)].map((_, index) => index);

    return (
        <div style={{display: 'flex'}}>
            <div style={{display: 'flex', width: '20%', alignItems: 'center'}}>
                <small>Show</small>
                <Form.Control as="select" className="mx-2" style={{width: 50, minWidth: 'unset', padding: `5px 0px `, height: 'unset', fontSize: '.875em'}} onChange={(e) => {
                    setPageSize(parseInt(e.target.value))
                    setOffset(0)
                    setLimit(parseInt(e.target.value))
                }
                }>
                    {rowsPerPage.map(r => 
                        <option value={r} selected={r == pageSize}>{r}</option>
                    )}
                </Form.Control>
                <small>entries</small>
            </div>
            <Pagination size="sm" className="flex-wrap m-0" style={{justifyContent: 'end', width: '80%'}}>
                <Pagination.First onClick={() => {
                    setOffset(0)
                    setLimit(pageSize)
                    setActiveIndex(0)
                    }} />
                { activeIndex > 0 && <Pagination.Prev  onClick={() => {
                    setOffset((activeIndex - 1) * pageSize)
                    setLimit((activeIndex - 1) * pageSize + pageSize)
                    setActiveIndex(activeIndex - 1)
                }} /> }
                {activeIndex > 0 ?
                <>
                    <Pagination.Item 
                        active={activeIndex == 0}
                        key={0}
                        onClick={() => {
                            setOffset(0)
                            setLimit(pageSize)
                            setActiveIndex(0)
                        }}>
                            {1}
                    </Pagination.Item>
                 <Pagination.Ellipsis />
                </> :null }
                
                    {pageNumbers.slice(activeIndex, (10 + activeIndex)).map((index) => 
                        <Pagination.Item 
                            active={activeIndex == index}
                            key={index}
                            onClick={() => {
                                setOffset((index) * pageSize)
                                setLimit(((index) * pageSize) + pageSize)
                                setActiveIndex(index)
                            }}>
                                {index + 1}
                        </Pagination.Item>
                    )}
                {(pages - activeIndex) > 10  ?
                <>
                    <Pagination.Ellipsis />
                    <Pagination.Item 
                        active={activeIndex ==  (pages - 1)}
                        key={pages - 1}
                        onClick={() => {
                            setOffset((pages - 1) * pageSize)
                            setLimit((pages - 1) * pageSize + 1)
                            setActiveIndex(pages - 1)
                        }}>
                            {pages}
                    </Pagination.Item>
                </>
                : null}
                { activeIndex < (pages - 1)  &&
                 <Pagination.Next  onClick={() => {
                    setOffset((activeIndex + 1) * pageSize)
                    setLimit((activeIndex + 1) * pageSize + pageSize)
                    setActiveIndex(activeIndex + 1)
                }} /> }
                <Pagination.Last  onClick={() => {
                    setOffset((pages - 1) * pageSize)
                    setLimit((pages - 1) * pageSize + 1)
                    setActiveIndex(pages - 1)
                }} />
            </Pagination>
        </div>
    )
}

export default Paginate;