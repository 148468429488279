import React from "react";

function Restricted() {
    return(
        <>
            <section className="restricted disabled">
                <div className="restricted-notification-box">
                    <p>You are not allowed to use this application. Please contact your Business Unit Compliance Officer to gain access.</p>
                </div>
            </section>
        </>
    );
}

export default Restricted;