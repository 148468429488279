import React from 'react';
import DncTable from '../molecules/DncTable';
import { DNCContext } from '../../context';
import { useContext } from 'react';
import { Container, Spinner } from 'react-bootstrap'; 
import { useNavigate } from "react-router-dom";
function FetchDnc() {
    const { filteredData:dnc, limit, offset, contact, setContact } = useContext(DNCContext)
    const navigate = useNavigate()
    const renderItems = () => {
        return dnc.slice(offset, limit).map((item) =>
             <DncTable  
                onClick={() => {
                    setContact(item)
                    navigate('/edit-contact')
                }}
                key={item.id}
                item={item} />);
    };

    return (
        <>
            <tbody>
                {renderItems()}
            </tbody>            
        </>

    );
}

export default FetchDnc;
