import React, {useState, useEffect, useContext} from 'react';
import FetchDnc from './components/organisms/FetchDnc';
import AddDnc from './components/organisms/AddDnc';
import DncTableContainer from './components/organisms/DncTableContainer';
import PrimaryHeader from './components/organisms/PrimaryHeader';
import SubHeader from './components/organisms/subHeader';
import Footer from './components/organisms/Footer';
import Restricted from './components/organisms/Restricted';
import Success from './components/organisms/Success';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { SignInButton } from './components/SignInButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./graph";
import { SignOutButton } from "./components/SignOutButton";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DNCContext, api_token, api_url } from './context';
import ImportCSV from './components/organisms/ImportCSV';

const WelcomeContent = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [dncData, setDncData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState(null);
   function RequestProfileData() {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
      }).then((response) => {
          callMsGraph(response.accessToken).then(response => setGraphData(response));
      });
  }

  useEffect(() => {
    if(window.location.pathname === '/add-contact' || 
    window.location.pathname === '/edit-contact' || 
    window.location.pathname === '/success' ||
    window.location.pathname === '/import-csv') {
      document.body.classList.add('hide-sub-header');
    }
  })

  return (
    <DNCContext.Provider 
        value={{
          dncData,
          setDncData,
          filteredData,
          setFilteredData,
          offset,
          setOffset,
          limit,
          setLimit,
          pageSize,
          setPageSize,
          activeIndex,
          setActiveIndex,
          loading,
          setLoading,
          contact,
          setContact
        }}>
      <Router>

        <main id="main" className="app-main pb-5">
          <SubHeader />
          <Routes>
            <Route path="/add-contact" element={<AddDnc />} />
            <Route path="/edit-contact" element={<AddDnc edit={true} />} />
            <Route path="/success" element={<Success />} />
            <Route path="/import-csv" element={<ImportCSV />} />
            <Route path="/" element={<DncTableContainer />} />
          </Routes>
          <Restricted />
        </main>

      </Router>

    </DNCContext.Provider>
  );
};


function App() {
  return (
    <>
      <AuthenticatedTemplate>
      <SignOutButton />
      <div className="App">
        <div className="app-body">
          <PrimaryHeader />
          <WelcomeContent />
          <Footer /> 
        </div>
      </div>
      </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
        <SignInButton/>
      </UnauthenticatedTemplate>
      </> 
  );
}

export default App;
